import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    const header = document.querySelector('.header-top')
    document.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        header.classList.add('header-top_black')
      } else {
        header.classList.remove('header-top_black')
      }
    })
  }

}